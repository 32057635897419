const env = process.env.NODE_ENV;

const tesnetConfig = {
  tokenVestingContractAddress: "0x5ED75c4FC1Ed359AAe12E142c570F2A8AC492402",
  explorerRootURL: "http://polygonscan.com/",
  tokenVestingContractAbi: require("./assets/abi/TokenVesting.json"),
  tokenVestingContractBytecode: require("./assets/bytecode/TokenVesting.json")
    .bytecode,
  erc20ContractAbi: require("./assets/abi/ERC20.json"),
};

const mainnetConfig = {
  tokenVestingContractAddress: "0x5ED75c4FC1Ed359AAe12E142c570F2A8AC492402",
  explorerRootURL: "http://polygonscan.com/",
  tokenVestingContractAbi: require("./assets/abi/TokenVesting.json"),
  tokenVestingContractBytecode: require("./assets/bytecode/TokenVesting.json")
    .bytecode,
  erc20ContractAbi: require("./assets/abi/ERC20.json"),
};
const defaultConfig = mainnetConfig;

export let globalConfig;
switch (env) {
  case "development":
  case "preproduction":
    globalConfig = tesnetConfig;
    break;
  case "production":
    globalConfig = tesnetConfig;
    break;
  case undefined:
    globalConfig = defaultConfig;
    break;
}
